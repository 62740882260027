import styled from '@emotion/styled'
import { Image } from 'antd';

export const SingleAntImageContainer = styled(Image)`
  width: auto;
  height: 100%;
  .ant-image-img {
    /* width: ${props => props.width}px;
    height: ${props => props.height}px; */
    border-radius: ${props => props.borderradius};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`